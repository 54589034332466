'use client'
import { styled } from '../../config'

import * as React from 'react'

const SvgComponent = (props) => {
  const { active, ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={50}
      height={50}
      fill="none"
      {...rest}>
      <g clipPath="url(#a)">
        <path
          fill="#121316"
          d="M25 50c13.807 0 25-11.193 25-25S38.807 0 25 0 0 11.193 0 25s11.193 25 25 25Z"
        />
        <path
          fill="url(#b)"
          stroke="url(#c)"
          strokeWidth={0.625}
          d="M47.187 25c0-12.254-9.934-22.188-22.188-22.188S2.812 12.746 2.812 25s9.933 22.188 22.187 22.188c12.254 0 22.188-9.934 22.188-22.188Z"
        />
        <path
          fill="#fff"
          d="M34.822 31.25h-3.43c0-3.912-.68-6.516-2.076-7.959-1.88-1.945-5.18-1.859-8.998-1.765l-.815.02v9.703h-3.431V17.94h1.716c.81 0 1.642-.02 2.446-.041 4.208-.11 8.56-.222 11.478 2.794 2.093 2.162 3.11 5.616 3.11 10.557Z"
        />
      </g>
      <defs>
        <linearGradient
          id="b"
          x1={24.999}
          x2={24.999}
          y1={2.813}
          y2={47.188}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#121316" />
          <stop offset={1} stopColor="#777E92" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="c"
          x1={24.999}
          x2={24.999}
          y1={2.5}
          y2={47.5}
          gradientUnits="userSpaceOnUse">
          <stop stopOpacity={0.1} />
          <stop offset={1} stopColor="#211E1E" stopOpacity={0.05} />
        </linearGradient>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h50v50H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const NinjaDefault = styled(SvgComponent)
